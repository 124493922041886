let _jStorage = '';
const jstKeyword = 'tb-keypadlokal';

const getJStorage = (forceGet?: boolean): string => {
    if (_jStorage !== '' && !forceGet) {
        return _jStorage;
    }

    const newJStorage = window.localStorage.getItem('jStorage') || '{}';
    _jStorage = formatJStorage(newJStorage);

    return _jStorage;
};

/**
 * This function is needed since some of the values in jStorage contains line break.
 * Replacing all line breaks with white spaces.
 */
const formatJStorage = (js: string) => {
    const newJS = js.replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t');

    return newJS;
};

const getJStorageKeyboardVisibility = (): boolean => {
    const jst = JSON.parse(getJStorage());

    return jst[jstKeyword] === 'true' ? true : false;
};

const setJStorageKeyboardVisibility = (visible: boolean) => {
    const formattedJst = formatJStorage(getJStorage());
    const jst = JSON.parse(formattedJst);

    jst[jstKeyword] = visible.toString();

    setJStorage(JSON.stringify(jst));
};

const setJStorage = (newJs: string) => {
    window.localStorage.setItem('jStorage', newJs);
    _jStorage = newJs;
};

export { getJStorageKeyboardVisibility, setJStorageKeyboardVisibility };
