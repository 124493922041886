import { useEffect, useRef, useState } from 'react';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faWrench } from '@fortawesome/pro-solid-svg-icons/faWrench';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiFirmabruker, apiMe } from '../helpers/api';
import { MenuItems } from '../helpers/enums';
import { getJStorageKeyboardVisibility, setJStorageKeyboardVisibility } from '../helpers/jStorage';
import { ApiMe, Firmabruker } from '../helpers/types';
import Bookmark from './Bookmark';
import CompanyPassword from './CompanyPassword';
import LinkPage from './LinkPage';
import SystemMenu from './SystemMenu';
import './App.less';

type Props = {
    whoAmI?: ApiMe;
    firmabrukere?: Firmabruker[];
};

const App: React.FC<Props> = (props: Props) => {
    const menuRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasFetched, setHasFetched] = useState<boolean>(false);
    const [whoAmI, setWhoAmI] = useState<ApiMe | undefined>(props.whoAmI);
    const [firmabrukere, setFirmabrukere] = useState<Firmabruker[]>();
    const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItems>();
    const [showOnScreenKeyboard, setShowOnScreenKeyboard] = useState<boolean>(false);
    const [isMenuShowing, setIsMenuShowing] = useState<boolean>(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setShowOnScreenKeyboard(getJStorageKeyboardVisibility());
    }, []);

    const fetchData = () => {
        if (hasFetched) {
            return;
        }

        if (!props.whoAmI) {
            Promise.all([apiMe.get(''), apiFirmabruker.get('')])
                .then((res) => {
                    const whoAmI = res[0].data as ApiMe;
                    const firmabrukere = res[1].data as Firmabruker[];

                    setWhoAmI(whoAmI);
                    setFirmabrukere(firmabrukere);
                    setLoading(false);
                    setHasFetched(true);
                })
                .catch(() => {
                    console.error('Feilet på å laste data.');
                    setLoading(false);
                });
        } else if (props.whoAmI) {
            apiFirmabruker
                .get('')
                .then((res) => {
                    setFirmabrukere(res.data);
                    setLoading(false);
                    setHasFetched(true);
                })
                .catch(() => {
                    console.error('Feilet på å laste data fra firmabrukere');
                    setLoading(false);
                });
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        const elementNode = event.target as HTMLElement;
        if (
            elementNode.classList.contains('SystemButtonIcon') ||
            elementNode.classList.contains('SysMenuButton') ||
            (elementNode.parentElement &&
                (elementNode.parentElement.classList.contains('SystemButtonIcon') ||
                    elementNode.parentElement.classList.contains('SysMenuButton')))
        ) {
            return;
        }

        if (menuRef && menuRef.current && !menuRef.current.contains(elementNode)) {
            setIsMenuShowing(false);
        }
    };

    const findUserKey = (): string => {
        let nokkel = '';

        let brukerId = whoAmI?.Id;
        if (!brukerId) {
            brukerId = -1;
            console.error(`BrukerId <${whoAmI?.Id}> fra API Me er ugyldig!`);
        }

        if (firmabrukere && firmabrukere.length > 0 && brukerId) {
            const user = firmabrukere.find((user: Firmabruker) => user.AnsattId === brukerId);
            nokkel = user ? user.Nokkel : '';
        }

        return nokkel;
    };

    const handleMenuItemClick = (item: MenuItems) => {
        switch (item) {
            case MenuItems.Menu:
                setSelectedMenuItem(MenuItems.Menu);
                break;
            case MenuItems.Link:
                setSelectedMenuItem(MenuItems.Link);
                break;
            case MenuItems.Bookmark:
                setSelectedMenuItem(MenuItems.Bookmark);
                break;
            case MenuItems.CompanyPassword:
                setSelectedMenuItem(MenuItems.CompanyPassword);
                break;
            case MenuItems.Keyboard:
                handleKeyboardVisibility();
                break;
            default:
                setSelectedMenuItem(MenuItems.None);
        }
    };

    const getVisibleComponent = (): React.ReactNode => {
        switch (selectedMenuItem) {
            case MenuItems.Menu:
                return (
                    whoAmI &&
                    firmabrukere && (
                        <SystemMenu
                            clickHandler={handleMenuItemClick}
                            whoAmI={whoAmI}
                            firmabrukere={firmabrukere}
                            showOnScreenKeyboard={showOnScreenKeyboard}
                        />
                    )
                );
            case MenuItems.Link:
                return <LinkPage firmabrukere={firmabrukere} whoAmI={whoAmI} />;
            case MenuItems.Bookmark:
                return <Bookmark userKey={findUserKey()} />;
            case MenuItems.CompanyPassword:
                return <CompanyPassword user={whoAmI} />;
            default:
                return '';
        }
    };

    const handleKeyboardVisibility = () => {
        setLoading(true);
        setJStorageKeyboardVisibility(!showOnScreenKeyboard);

        /**
         * Reload to ensure
         */
        window.location.reload();
    };

    const handleOnClick = (event: React.MouseEvent) => {
        if (!hasFetched) {
            setLoading(true);
        }
        setIsMenuShowing(!isMenuShowing);
        const target = event.currentTarget as HTMLElement;
        target.blur();
        handleMenuItemClick(MenuItems.Menu);
        fetchData();
    };

    return (
        <div className="SysMenu">
            <button className="SysMenuButton" onClick={handleOnClick}>
                <FontAwesomeIcon className="SystemButtonIcon" icon={loading ? faSpinner : faWrench} spin={loading} />
            </button>
            {isMenuShowing ? (
                <div className="SystemMenuWrapper" ref={menuRef}>
                    {getVisibleComponent()}
                </div>
            ) : null}
        </div>
    );
};

export default App;
