import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SystemMenuItem.less';

interface Props {
    clickHandler?: () => void;
    href?: string;
    text: string;
}

const SystemMenuItem: React.FC<Props> = ({ clickHandler, href, text }: Props) => {
    return (
        <div className="SystemMenuItem">
            <a
                href={href ? href : ''}
                onClick={(e) => {
                    !href ? e.preventDefault() : '';
                    clickHandler?.();
                }}
            >
                <span>{text}</span>
                {href ? <FontAwesomeIcon className="SystemMenuLink" icon={faExternalLinkAlt} /> : ''}
            </a>
        </div>
    );
};

export default SystemMenuItem;
