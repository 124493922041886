import { ChangeEvent, useEffect, useState } from 'react';
import Select, { ValueType } from 'react-select';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiEpost, apiSMS, getDepartments, getEmployees } from '../helpers/api';
import { ApiMe, Department, EmailMessage, Employee, Firmabruker, SMSMessage } from '../helpers/types';
import './LinkPage.less';

type Props = {
    firmabrukere?: Firmabruker[];
    whoAmI?: ApiMe;
};

const LinkPage: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<Department>();
    const [message, setMessage] = useState<string>('Bruk følgende link til å logge deg inn i Tidsbanken');
    const [sendAsSMS, setSendAsSMS] = useState<boolean>(false);
    const [sendAsEmail, setSendAsEmail] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loggedInUser, setLoggedInUser] = useState<Employee>();
    const [sendSucceeded, setSendSucceeded] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);

    useEffect(() => {
        Promise.all([getEmployees(), getDepartments()])
            .then((res) => {
                const allEmployees = res[0].data.value as Employee[];
                const allDepartments = res[1].data.value as Department[];
                const employees = allEmployees.filter((employee: Employee) => employee.Aktiv && !employee.Sluttet);
                const departments = allDepartments.filter((department: Department) => department.Synlig);
                const loggedInUser = employees.find((emp: Employee) => emp.Id === (props.whoAmI && props.whoAmI.Id));

                setEmployees(employees);
                setDepartments(departments);
                setLoading(false);
                setLoggedInUser(loggedInUser);
            })
            .catch(() => {
                console.error('Klarte ikke å hente ansatte');
                setLoading(false);
            });
    }, []);

    const handleDepartmentChange = (value: ValueType<Department>) => {
        const department = value as Department;
        setSelectedDepartment(department);
        setErrorMessage('');
    };

    const handleEmployeeChange = (values: ValueType<Employee>) => {
        const employees = values as Employee[];
        setSelectedEmployees(employees);
        setErrorMessage('');
    };

    const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
        setErrorMessage('');
    };

    const findEmployeesAndSendMessages = async () => {
        setIsSending(true);
        setErrorMessage('');

        const allSelectedEmployees: Employee[] = findAllSelectedEmployees();

        if (!allSelectedEmployees || allSelectedEmployees.length < 1) {
            setErrorMessage('Ingen ansatte er valgt');
            setIsSending(false);
            return;
        }

        sendMessages(allSelectedEmployees)
            .then(() => {
                setSendSucceeded(true);

                setTimeout(() => {
                    setSendSucceeded(false);
                }, 3000);
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    const findAllSelectedEmployees = (): Employee[] => {
        let allSelectedEmployees: Employee[] = [];

        if (selectedDepartment) {
            allSelectedEmployees = employees.filter((emp: Employee) => emp.Avdeling.Id === selectedDepartment.Id);
        }

        if (selectedEmployees.length > 0) {
            selectedEmployees.forEach((emp: Employee) => {
                const index = allSelectedEmployees.findIndex((employee: Employee) => employee.Id === emp.Id);
                if (index === -1) {
                    allSelectedEmployees.push(emp);
                }
            });
        }

        return allSelectedEmployees;
    };

    const sendMessages = async (employees: Employee[]) => {
        const emailMessages: EmailMessage[] = [];
        const smsMessages: SMSMessage[] = [];
        const tbMessages: string[] = [];
        const senderId = loggedInUser ? loggedInUser.Id : -1;
        const missingRecipientContactInfo = false;

        employees.forEach((employee: Employee) => {
            const personalUrl = constructPersonalUrl(employee);
            const personalMessage = `${message}: ${personalUrl}`;

            let tbMessageAPIURL =
                window.location.origin + `/smshjelper.asp?smsfunk=lagre&fra=${senderId}&til=${employee.Id}`;

            if (sendAsEmail && employee.Epost) {
                if (!loggedInUser || !loggedInUser.Epost) {
                    setErrorMessage('Du må registrere din egen epost-adresse i ansattkortet for å kunne sende epost');
                } else {
                    const newMessage: EmailMessage = {
                        from: loggedInUser.Epost!.replace(/\r/g, ''),
                        to: employee.Epost.replace(/\r/g, ''),
                        subject: 'Påloggingsinformasjon til Tidsbanken',
                        plain: personalMessage,
                    };

                    tbMessageAPIURL += `&epost=${employee.Epost}`;
                    emailMessages.push(newMessage);
                }
            } else if (sendAsEmail && !employee.Epost && !missingRecipientContactInfo) {
                setErrorMessage('En eller flere ansatte mangler kontaktopplysninger');
            }

            if (sendAsSMS) {
                const phoneNumber = findCell(employee);

                if (phoneNumber) {
                    const newMessage: SMSMessage = {
                        to: phoneNumber,
                        content: personalMessage,
                    };

                    tbMessageAPIURL += '&mob=' + phoneNumber;
                    smsMessages.push(newMessage);
                } else if (!missingRecipientContactInfo) {
                    setErrorMessage('En eller flere ansatte mangler kontaktopplysninger');
                }
            }

            // This has to happen last so that a message don't mess with 'epost' and 'mob' keywords.
            tbMessageAPIURL += `&mld=${personalMessage}`;
            tbMessages.push(tbMessageAPIURL);
        });

        if (sendAsSMS && smsMessages.length) {
            await sendSMS(smsMessages);
        }

        if (sendAsEmail && emailMessages.length) {
            await sendEmails(emailMessages);
        }

        await sendTBMessages(tbMessages);
    };

    const sendTBMessages = async (tbMessages: string[]) => {
        const requests = tbMessages.map((tbMessage: string) => {
            const hasMobQueryParam = hasURLParam(tbMessage, 'mob');
            const hasEmailQueryParam = hasURLParam(tbMessage, 'epost');

            return new Promise((resolve, reject) => {
                fetch(tbMessage, {
                    credentials: 'include',
                })
                    .then((res) => res.text())
                    .then((messageId) => {
                        // All messages sent as email get's their receipt when loading "meldinger-ny.asp". Therefore we only want to send a receipt for sent SMS'!
                        if (hasMobQueryParam) {
                            const tbMessageReceiptAPIURL =
                                window.location.origin +
                                `/smshjelper.asp?smsfunk=kvitter&id=${messageId}&eksternid=RECEIPT&epost=${hasEmailQueryParam}`;

                            fetch(tbMessageReceiptAPIURL, {
                                credentials: 'include',
                            })
                                .then()
                                .catch();
                        }
                        return resolve(messageId);
                    })
                    .catch((err: Error) => reject(err));
            });
        });

        await Promise.all(requests).catch((err: Error) => console.error(err));
    };

    const sendSMS = (smsMessages: SMSMessage[]) => {
        return apiSMS.post('', { fromType: 0, messages: smsMessages });
    };

    const sendEmails = async (emailMessages: EmailMessage[]) => {
        emailMessages.forEach(async (message: EmailMessage) => {
            await apiEpost
                .post('', message)
                .then()
                .catch((e: Error) => {
                    console.error(e);
                });
        });
    };

    const constructUrl = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('key');
        return url;
    };

    const constructPersonalUrl = (employee: Employee): string => {
        const firmabruker =
            props.firmabrukere && props.firmabrukere.find((user: Firmabruker) => user.AnsattId === employee.Id);
        const employeeKey = firmabruker ? firmabruker.Nokkel : '';
        const url = constructUrl();
        url.searchParams.append('key', employeeKey);
        return url.href;
    };

    const findCell = (employee: Employee) => {
        return employee.Mobil ? employee.Mobil : employee.TlfPrivat ? employee.TlfPrivat : '';
    };

    const employeeLabel = (emp: Employee): string => {
        const cell = findCell(emp);
        return emp['Navn'] + (cell ? ' | ' + cell : '') + (emp['Epost'] !== '' ? ' | ' + emp['Epost'] : '');
    };

    function hasURLParam(url: string, queryKey: string): boolean {
        const urlParams = new URLSearchParams(url);
        const myParam = urlParams.get(queryKey);

        return myParam ? myParam.length > 0 : false;
    }

    return (
        <div className="LinkPage">
            {loading ? (
                <div className="LinkPageLoader">
                    LASTER{' '}
                    <span>
                        <i className="fa fa-spinner fa-spin" />
                    </span>
                </div>
            ) : (
                <>
                    <h3>Avdelinger:</h3>
                    <Select
                        options={departments}
                        getOptionLabel={(dep: Department) => dep['Navn']}
                        getOptionValue={(dep: Department) => dep['Id']}
                        isClearable={true}
                        onChange={handleDepartmentChange}
                    />

                    <h3>Ansatte:</h3>
                    <Select
                        options={employees}
                        getOptionLabel={(emp: Employee) => employeeLabel(emp)}
                        getOptionValue={(emp: Employee) => emp['Id'].toString()}
                        isMulti={true}
                        onChange={handleEmployeeChange}
                    />

                    <h3>Melding:</h3>
                    <textarea className="LinkPageMessage" onChange={handleMessageChange} value={message} rows={3} />
                    <label>Forhåndsvisning:</label>
                    <span>
                        {message}: {constructUrl().href}
                        ?key=&lt;mottakers-personlige-nøkkel&gt;
                    </span>

                    <div className="SendOptions">
                        Send meldingen som:
                        <form className="SendOptionsForm">
                            <div className="TBMessage">
                                <label className="SendOptionsOption">
                                    <input
                                        className="SendOptionsOptionInput"
                                        name="tidsbanken-message"
                                        type="checkbox"
                                        checked={true}
                                        disabled={true}
                                        onClick={() => {
                                            alert('Meldinger sendes alltid som Tidsbanken-melding også.');
                                        }}
                                    />
                                    Tidsbanken-melding
                                </label>
                                <span className="SendOptionsInfoText">
                                    Meldinger sendes alltid som Tidsbanken-melding.
                                </span>
                            </div>

                            <br />

                            <label className="SendOptionsOption">
                                <input
                                    className="SendOptionsOptionInput"
                                    name="sms-message"
                                    type="checkbox"
                                    checked={sendAsSMS}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setSendAsSMS(event.target.checked);
                                    }}
                                />
                                SMS (2 kr)
                            </label>

                            <br />

                            <label className="SendOptionsOption">
                                <input
                                    className="SendOptionsOptionInput"
                                    name="email-message"
                                    type="checkbox"
                                    checked={sendAsEmail}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setSendAsEmail(event.target.checked);
                                    }}
                                />
                                Epost
                            </label>
                        </form>
                    </div>
                    <div className="SendButtonWrapper">
                        {sendSucceeded && <SuccessMessage />}
                        {isSending ? <FontAwesomeIcon className="SendSpinner" icon={faSpinner} spin={true} /> : ''}
                        <button className="SendLinkButton" onClick={findEmployeesAndSendMessages} disabled={isSending}>
                            Send
                        </button>
                    </div>

                    <span className="ErrorMessage">{errorMessage}</span>
                </>
            )}
        </div>
    );
};

const SuccessMessage = (): JSX.Element => {
    return (
        <div className="SendSucceed">
            <FontAwesomeIcon icon={faCheck} />
            <span className="SendSucceedMessage">Meldingene ble sendt</span>
        </div>
    );
};

export default LinkPage;
