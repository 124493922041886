import axios from 'axios';

const sandkasseDomainURL = 'sandkasse.tidsbanken.net';
const minDomainURL = 'min.tidsbanken.net';

export const getEnvironmentOld = (domene: string) =>
    window.location.hostname !== minDomainURL
        ? `https://api-${domene}-sandkasse.tidsbanken.net`
        : `https://api-${domene}.tidsbanken.net`;

export const getEnvironmentNew = (domene: string) =>
    window.location.hostname !== minDomainURL
        ? `https://${sandkasseDomainURL}/api/${domene}`
        : `https://${minDomainURL}/api/${domene}`;

const employeeApiUrl = `${getEnvironmentOld('ansatte')}/`;
const apiMeUrl = `${getEnvironmentOld('me')}/`;
const apiSMSUrl = `${getEnvironmentOld('sms')}/send`;

// Memoizer
const memoize = <F extends (...args: any[]) => any>(func: F) => {
    const cache: { [key: string]: ReturnType<F> | undefined } = {};

    return (...args: Parameters<F>) => {
        const key = JSON.stringify(args);
        const cached = cache[key];

        if (cached !== undefined) {
            return cached;
        }

        return (cache[key] = func(...args));
    };
};

const apiMe = axios.create({
    baseURL: apiMeUrl,
    withCredentials: true,
});

const apiFirmabruker = axios.create({
    baseURL: 'https://auth.tidsbanken.net/api/Key',
    withCredentials: true,
});

const apiSMS = axios.create({
    baseURL: apiSMSUrl,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const fetchCompanyLogin = axios.create({
    baseURL: apiSMSUrl + '/logininfo',
    withCredentials: true,
});

const apiEpost = axios.create({
    baseURL:
        'https://sendepost.tidsbanken.net/api/Send?code=gf5WobfbKVcmNvmJ5/PldB1OV/mkPKLvO75k3qiXaX12KziKRDsIFA==',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const employeeFetch = axios.create({
    baseURL: employeeApiUrl,
    withCredentials: true,
});

const departmentFetch = axios.create({
    baseURL: employeeApiUrl,
    withCredentials: true,
});

const getEmployees = memoize(() => {
    return employeeFetch.get('Ansatte?$expand=Avdeling');
});

const getDepartments = memoize(() => {
    return departmentFetch.get('Avdeling');
});

export { apiEpost, apiFirmabruker, apiMe, apiSMS, fetchCompanyLogin, getDepartments, getEmployees };
