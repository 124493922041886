import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import './Bookmark.less';

type Props = {
    userKey?: string;
};

const Bookmark: React.FC<Props> = (props: Props) => {
    const [qrCodeURL, setQRCodeURL] = useState<string | null>(null);

    const originalUrl = props.userKey ? `${window.location.href}?key=${props.userKey}` : 'Klarte ikke å generere URL.';

    // Function to strip multiple occurrences of ?key= in the URL
    const stripMultipleKeyParams = (url: string): string => {
        const parts = url.split('?key=');
        if (parts.length > 2) {
            return parts.slice(0, -1).join('?key=');
        }
        return url;
    };

    const personalUrl = stripMultipleKeyParams(originalUrl);

    useEffect(() => {
        async function generateQRCode() {
            try {
                const qrCodeDataURL = await QRCode.toDataURL(personalUrl);
                setQRCodeURL(qrCodeDataURL);
            } catch (error) {
                console.error('Error generating QR code:', error);
            }
        }
        generateQRCode();
    }, [personalUrl]);

    return (
        <div className="Bookmark">
            <p className="BookmarkHeading">Din personlige lenke:</p>
            {qrCodeURL && (
                <div className="QRCodeContainer">
                    <p>Skann med ditt mobilkamera eller trykk på lenken under og deretter lagre nettsiden som et bokmerke</p>
                    <a href={personalUrl} target="_blank" rel="noopener noreferrer">
                        <img src={qrCodeURL} alt="QR Code" />
                    </a>
                </div>
            )}
            <a href={personalUrl}>{personalUrl}</a>
        </div>
    );
};

export default Bookmark;
