import { MenuItems } from '../helpers/enums';
import { ApiMe, Firmabruker } from '../helpers/types';
import SystemMenuItem from './SystemMenuItem';
import './SystemMenu.less';

type Props = {
    clickHandler: (menu: MenuItems) => void;
    whoAmI: ApiMe;
    firmabrukere: Firmabruker[];
    showOnScreenKeyboard: boolean;
};

const SystemMenu: React.FC<Props> = (props: Props) => {
    return (
        <div className="SystemMenu">
            {props.whoAmI.ErAdmin && (
                <>
                    <SystemMenuItem href={'/systeminnstillinger.asp'} text="Innstillinger" />
                    <SystemMenuItem href={'/kartotek_meny.asp'} text="Kartoteker" />
                    <SystemMenuItem href={'/systemkalender.asp'} text="Kalender" />
                    <SystemMenuItem href={'/integrasjon'} text="Integrasjoner" />

                    <hr />

                    <SystemMenuItem href={'/feilkontroll.asp'} text="Feilkontroll" />
                    <SystemMenuItem href={'/vedlikeholdsfunksjoner.asp'} text="Flere vedlikeholdsfunksjoner" />
                    <SystemMenuItem href={'/firmaopplysninger.asp'} text="Firmaopplysninger" />

                    <hr />

                    <SystemMenuItem
                        clickHandler={() => {
                            props.clickHandler(MenuItems.Link);
                        }}
                        text="Send link til side"
                    />
                </>
            )}

            <SystemMenuItem
                clickHandler={() => {
                    props.clickHandler(MenuItems.Bookmark);
                }}
                text="Lag bokmerke"
            />

            {props.whoAmI.ErAdmin && (
                <SystemMenuItem
                    clickHandler={() => {
                        props.clickHandler(MenuItems.CompanyPassword);
                    }}
                    text="Motta firmapassord"
                />
            )}

            <hr />

            <SystemMenuItem
                clickHandler={() => {
                    props.clickHandler(MenuItems.Keyboard);
                }}
                text={`${props.showOnScreenKeyboard ? 'Skjul' : 'Vis'} skjermtastatur på denne enhet`}
            />
        </div>
    );
};

export default SystemMenu;
