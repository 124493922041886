import { useState } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchCompanyLogin } from '../helpers/api';
import { ApiMe } from '../helpers/types';
import './CompanyPassword.less';

type Props = {
    user?: ApiMe;
};

const SuccessMessage = (): JSX.Element => {
    return (
        <div className="response success">
            <FontAwesomeIcon icon={faCheck} />
            <span className="response-message">SMS sendt</span>
        </div>
    );
};

const ErrorMessage = (): JSX.Element => {
    return (
        <div className="response error">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <span className="response-message">SMS sending feilet, prøv igjen!</span>
        </div>
    );
};

const CompanyPassword: React.FC<Props> = (props: Props) => {
    const [sendSucceeded, setSendSucceeded] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [sendingError, setSendingError] = useState<boolean>(false);

    const handleButtonClick = async () => {
        setIsSending(true);
        fetchCompanyLogin
            .get('')
            .then(() => {
                setSendSucceeded(true);
                setTimeout(() => {
                    setSendSucceeded(false);
                }, 3000);
            })
            .catch(() => {
                setSendingError(true);
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    return (
        <div className="CompanyPassword">
            <h4 className="heading">Motta firmapassord</h4>
            <p>Som administrator kan du motta pålogginsinformasjon på SMS ved å trykke på knappen under.</p>
            <b>Merk</b>

            <div className="orange">
                Vår anbefaling er at det kun er superbrukere som kjenner til firma-ID og firmapassord, og at denne
                informasjonen IKKE deles med standard brukere/ansatte. Standard brukere logger på Tidsbanken med eget
                ansattnummer og pin-kode eller ved hjelp av personlig lenke.
            </div>

            <b>Pålogging på fellesenheter</b>
            <p>For pålogging på en fellesenhet benyttes:</p>

            <div className="blue">
                <b>Firma-ID: </b>
                {props.user?.FirmaId}
                <br></br>
                <b>Firmapassord: </b>
                <span>Mottatt på SMS</span>
            </div>

            <div className="sms-buttom">
                <button className="button" onClick={handleButtonClick} disabled={isSending}>
                    Motta firmapassord
                </button>
                {isSending && <FontAwesomeIcon className="spinner" icon={faSpinner} spin={true} />}
                {sendSucceeded && <SuccessMessage />}
                {sendingError && <ErrorMessage />}
            </div>
        </div>
    );
};

export default CompanyPassword;
